<template>
  <a-result status="404" title="404" sub-title="Sorry, The page you visited does not exist.">
    <template #extra>
      <a-button type="primary" @click="$router.replace('/')">
        Go to the homepage
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
button:hover{
  background: #EE4B46!important;
  border-color: #EE4B46!important;
}
</style>